import '../../assets/images/background1.JPG';
import { TbDrone } from "react-icons/tb";
import { BsFillCameraFill } from "react-icons/bs";
import { IoFastFood } from "react-icons/io5";
import { FaShuttleVan } from "react-icons/fa";
import E1 from '../../assets/images/events/01.jpeg';
import E2 from '../../assets/images/events/02.jpeg';
import E3 from '../../assets/images/events/03.jpeg';
import E4 from '../../assets/images/events/04.jpeg';
import E5 from '../../assets/images/events/05.jpeg';
import E6 from '../../assets/images/events/06.jpeg';
import E7 from '../../assets/images/events/07.jpeg';


export class EventInclusions {
    public static readonly DRONE = {
        title : 'Drone Footages',
        icon : <TbDrone/>
    }
    public static readonly PHOTO = {
        title : 'Camera Footages',
        icon : <BsFillCameraFill/>
    }
    public static readonly FOOD = {
        title : 'Food & Beverages',
        icon : <IoFastFood/>
    }
    public static readonly TRANSPORT = {
        title : 'Transport',
        icon : <FaShuttleVan/>
    }
}

export class UpcomingEvents {
    public static list = [
        {
            id : '01',
            name : 'Kithulgala - Rafting/Canyoning',
            date : '10/06',
            price : '8,500',
            inclusion : [EventInclusions.FOOD],
            image : E1,
            url : 'https://docs.google.com/forms/d/e/1FAIpQLSd4DiGlRg9wCIL3jgqDJEoB_bTa5zpQUUmnxLNcqYdclT01lw/viewform'
        },
        {
            id : '02',
            name : 'Bambarakanda - Abseiling',
            date : '10/12',
            price : '23,500',
            inclusion : [EventInclusions.DRONE, EventInclusions.PHOTO, EventInclusions.FOOD, EventInclusions.TRANSPORT],
            image : E2,
            url : 'https://docs.google.com/forms/d/e/1FAIpQLSd4DiGlRg9wCIL3jgqDJEoB_bTa5zpQUUmnxLNcqYdclT01lw/viewform'
        },
        {
            id : '03',
            name : 'Mannakethi - Abseiling',
            date : '10/13',
            price : '16,500',
            inclusion : [EventInclusions.FOOD],
            image : E3,
            url : 'https://docs.google.com/forms/d/e/1FAIpQLSd4DiGlRg9wCIL3jgqDJEoB_bTa5zpQUUmnxLNcqYdclT01lw/viewform'
        },
        {
            id : '04',
            name : 'Puna Fall - Abseiling',
            date : '10/19',
            price : '18,500',
            inclusion : [EventInclusions.DRONE, EventInclusions.PHOTO, EventInclusions.FOOD, EventInclusions.TRANSPORT],
            image : E4,
            url : 'https://docs.google.com/forms/d/e/1FAIpQLSd4DiGlRg9wCIL3jgqDJEoB_bTa5zpQUUmnxLNcqYdclT01lw/viewform'
        },
        {
            id : '05',
            name : 'Rakshagala - Hike',
            date : '10/20',
            price : '12,500',
            inclusion : [EventInclusions.DRONE, EventInclusions.PHOTO, EventInclusions.FOOD, EventInclusions.TRANSPORT],
            image : E5,
            url : 'https://docs.google.com/forms/d/e/1FAIpQLSd4DiGlRg9wCIL3jgqDJEoB_bTa5zpQUUmnxLNcqYdclT01lw/viewform'
        },
        {
            id : '06',
            name : 'Lakshapana - Rope Jump',
            date : '10/26',
            price : '36,500',
            inclusion : [EventInclusions.DRONE, EventInclusions.PHOTO, EventInclusions.FOOD, EventInclusions.TRANSPORT],
            image : E6,
            url : 'https://docs.google.com/forms/d/e/1FAIpQLSd4DiGlRg9wCIL3jgqDJEoB_bTa5zpQUUmnxLNcqYdclT01lw/viewform'
        },
        {
            id : '07',
            name : 'Lakshapana - Abseiling',
            date : '10/27',
            price : '18,500',
            inclusion : [EventInclusions.DRONE, EventInclusions.PHOTO, EventInclusions.FOOD, EventInclusions.TRANSPORT],
            image : E7,
            url : 'https://docs.google.com/forms/d/e/1FAIpQLSd4DiGlRg9wCIL3jgqDJEoB_bTa5zpQUUmnxLNcqYdclT01lw/viewform'
        },
    ]
}
